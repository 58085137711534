/* eslint-disable no-param-reassign */
import React, { useMemo, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import classnames from 'classnames'
import { Map } from 'immutable'
import { useSelector, useDispatch } from 'react-redux'
import { useToast } from '_/hooks/use-toast'

import RemoveIcon from '_assets/icons/ic-remove.svg'
import Textfield from '_components/textfield'
import Button from '_components/button'
import { Loader, Preview, useConfirm } from '@refera/ui-web'
import { Attachments } from '_/components/refera-ui'
import Svg from '_components/svg'
import useToggle from '_hooks/use-toggle'
import { userSelector } from '_modules/authentication/selectors'
import ConfirmRemoveDialog from '_/components/dialogs/ConfirmRemoveDialog'

import { importAttachments, getBudget } from '_/modules/budget/actions'
import { ORDER_DETAILS_FIELDS, ORDER_DETAILS_LABEL } from './reducer'
import useStyles from './styles'
import DeleteBudgetItemDialog from './delete-budget-item-dialog'
import PriceSection from './price-section'
import useRolePermission from '_/hooks/use-role-permission'

const Details = ({
  budget,
  budgetId,
  serviceOrderId,
  isCancelled,
  isOriginalBudget,
  isEdit,
  handleInputChange,
  onRadioChange,
  handleBudgetItemsChange,
  onAddBudgetItem,
  onRemoveBudgetItem,
  onAddPicture,
  onRemovePicture,
  isAbleToEdit,
  errors,
}) => {
  const { isAdmin } = useRolePermission()

  const styles = useStyles({ isCancelled })

  const dispatch = useDispatch()
  const { showToast } = useToast()
  const { isConfirmed } = useConfirm()
  const isDisabled = !isEdit || isCancelled || isOriginalBudget
  const [itemToDelete, setItemToDelete] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isDeleteBudgetItemDialogOpen, onToggleDeleteBudgetItemDialog] = useToggle()
  const defaultPreviewState = {
    visible: false,
    selectedIndex: undefined,
  }

  const [previewState, setPreviewState] = useState(defaultPreviewState)
  const PreviewComponent = useMemo(
    () => (
      <Preview
        open={previewState.visible}
        onClose={() => setPreviewState(() => defaultPreviewState)}
        selectedIndex={previewState.selectedIndex}
        images={budget?.budgetPictures?.map((item, index) =>
          item.id
            ? {
                id: item.id,
                url: item.picture,
              }
            : {
                id: index,
                url: URL.createObjectURL(item),
              }
        )}
      />
    ),
    [previewState, budget?.budgetPictures]
  )

  const user = useSelector(userSelector)
  const [scrollToTop, setScrollToTop] = useState(true)
  const [dialogMessage, setDialogMessage] = useState('')

  const onImportFiles = useCallback(async () => {
    // TODO: Refazer importação de anexos diretamente dentro do componente Attachements para evitar duplicação no futuro.
    // duplicado em src/components/budget/budget-form/index.jsx
    setDialogMessage(
      'Tem certeza que você deseja importar fotos/vídeos do chamado? Esta ação importa TODOS arquivos anexados pelo cliente.'
    )

    const confirmed = await isConfirmed()
    const hasAttachments = budget?.budgetPictures.length > 0

    try {
      if (confirmed && !hasAttachments) {
        await dispatch(importAttachments(serviceOrderId, budgetId))
      }

      if (confirmed && hasAttachments) {
        setDialogMessage(
          `Atenção\n\nEste chamado já possui anexos. Você pode estar gerando duplicação de arquivos. Importar mesmo assim?`
        )

        const reconfirmed = await isConfirmed()

        if (reconfirmed) {
          await dispatch(importAttachments(serviceOrderId, budgetId))
        }
      }

      showToast({
        type: 'success',
        message: 'Ação realizada com sucesso.',
      })

      dispatch(getBudget(serviceOrderId, budgetId)) // FORÇA ATUALIZAÇÃO DE TELA
    } catch (e) {
      showToast({
        type: 'error',
        message: e,
      })
    }
  }, [budget, dispatch, serviceOrderId, budgetId])

  const onDeleteItemClick = useCallback(
    event => {
      const { dataset } = event.currentTarget

      setItemToDelete({ ...dataset })
      onToggleDeleteBudgetItemDialog()
    },
    [onToggleDeleteBudgetItemDialog]
  )

  if (scrollToTop) {
    window.scrollTo(0, 0)
    setScrollToTop(false)
  }

  const onConfirmDeleteBudgetItem = useCallback(() => {
    onRemoveBudgetItem(itemToDelete)
    onToggleDeleteBudgetItemDialog()
  }, [itemToDelete, onRemoveBudgetItem, onToggleDeleteBudgetItemDialog])

  const handlePercentageToValue = (percentage, totalReference) => {
    return (percentage * totalReference) / 100.0
  }

  const itemValueCalculate = itemValue => {
    const partialTotal =
      handlePercentageToValue(budget?.referaCompletionPercentage, itemValue) +
      handlePercentageToValue(budget?.agencyCompletionPercentage, itemValue) +
      itemValue
    const totalValue =
      partialTotal + handlePercentageToValue(budget?.originalFinancialIncome, partialTotal)

    return totalValue.toFixed(2)
  }

  const renderValue = item => {
    if (isAdmin) {
      return item[ORDER_DETAILS_FIELDS.VALUE_DESCRIPTION] || 0
    }
    return itemValueCalculate(item[ORDER_DETAILS_FIELDS.VALUE_DESCRIPTION])
  }

  const renderDescription = useMemo(
    () =>
      budget?.budgetItems
        ?.sort((a, b) => a.id - b.id)
        ?.map((item, index) => (
          <Grid
            className={classnames(styles.inputsWrapper, { [styles.inputsWrapperRemove]: isEdit })}
            key={`description-group${index + 1}`}
          >
            <Typography variant="h5" component="p">
              {index + 1}
            </Typography>
            <Textfield
              className={styles.input}
              name={ORDER_DETAILS_FIELDS.ITEM_DESCRIPTION}
              inputProps={{ 'data-id': index }}
              variant="outlined"
              value={item[ORDER_DETAILS_FIELDS.ITEM_DESCRIPTION]}
              onChange={handleBudgetItemsChange}
              disabled={isDisabled || !isAbleToEdit}
            />
            <Textfield
              className={styles.input}
              name={ORDER_DETAILS_FIELDS.VALUE_DESCRIPTION}
              inputProps={{ 'data-id': index }}
              variant="outlined"
              value={renderValue(item)}
              onChange={handleBudgetItemsChange}
              type="number"
              disabled={isDisabled || !isAbleToEdit}
            />
            {isEdit && isAbleToEdit && (
              <button
                type="button"
                data-id={item.id}
                data-index={index}
                onClick={onDeleteItemClick}
                aria-label={`Remover item ${index + 1}`}
                className={styles.removeButton}
              >
                <Svg className={styles.removeIcon} icon={RemoveIcon} />
              </button>
            )}
          </Grid>
        )),
    [
      budget.budgetItems,
      handleBudgetItemsChange,
      isDisabled,
      isEdit,
      isAbleToEdit,
      onDeleteItemClick,
      styles.input,
      styles.inputsWrapper,
      styles.inputsWrapperRemove,
      styles.removeButton,
      styles.removeIcon,
    ]
  )

  const paymentOptions = useMemo(
    () => [
      {
        label: ORDER_DETAILS_LABEL[ORDER_DETAILS_FIELDS.ACCEPTS_WARRANTY],
        name: ORDER_DETAILS_FIELDS.ACCEPTS_WARRANTY,
        options: [
          {
            label: 'Não',
            value: false,
          },
          {
            label: 'Sim',
            value: true,
          },
        ],
      },
      {
        label: ORDER_DETAILS_LABEL[ORDER_DETAILS_FIELDS.WARRANTY_MONTHS],
        name: ORDER_DETAILS_FIELDS.WARRANTY_MONTHS,
        type: 'undefined',
        disabled: !budget[ORDER_DETAILS_FIELDS.ACCEPTS_WARRANTY],
        error: errors.get(ORDER_DETAILS_FIELDS.WARRANTY_MONTHS)?.first(),
      },
      {
        label: ORDER_DETAILS_LABEL[ORDER_DETAILS_FIELDS.VISIT_HAPPENED],
        name: ORDER_DETAILS_FIELDS.VISIT_HAPPENED,
        options: [
          {
            label: 'Não',
            value: false,
          },
          {
            label: 'Sim',
            value: true,
          },
        ],
      },
    ],
    [budget, errors]
  )

  const radioClasses = useCallback(
    checked => {
      if (!checked) {
        return {}
      }
      if (isOriginalBudget) {
        return { checked: styles.originalChecked, disabled: styles.originalDisabled }
      }

      if (isDisabled || !isAbleToEdit) {
        return { checked: styles.disabledChecked, disabled: styles.disabled }
      }

      return {}
    },
    [
      isDisabled,
      isAbleToEdit,
      isOriginalBudget,
      styles.disabled,
      styles.disabledChecked,
      styles.originalChecked,
      styles.originalDisabled,
    ]
  )

  const renderOptions = useCallback(
    item => (
      <FormControl component="fieldset" className={styles.fieldset} key={item.name}>
        <FormLabel component="p">{item.label}</FormLabel>
        {item.options ? (
          <RadioGroup name={item.name} className={styles.radioGroup} onChange={onRadioChange}>
            {item.options.map((option, index) => {
              return (
                <FormControlLabel
                  key={`${item.name}-option-${index + 1}`}
                  label={option.label}
                  control={
                    <Radio
                      color={isOriginalBudget ? '' : 'primary'}
                      classes={radioClasses(budget[item.name] === option.value)}
                      disabled={isCancelled || !isAbleToEdit}
                    />
                  }
                  checked={budget[item.name] === option.value}
                  value={option.value}
                  disabled={isDisabled || !isAbleToEdit}
                />
              )
            })}
          </RadioGroup>
        ) : (
          <Textfield
            className={styles.input}
            value={budget[item.name]}
            name={item.name}
            onChange={handleInputChange}
            disabled={isDisabled || !isAbleToEdit || item.disabled}
            {...(item.type && { type: item.type })}
            error={!!item.error}
            helperText={item.error}
          />
        )}
      </FormControl>
    ),
    [
      budget,
      handleInputChange,
      isCancelled,
      isDisabled,
      isAbleToEdit,
      isOriginalBudget,
      onRadioChange,
      radioClasses,
      styles.fieldset,
      styles.input,
      styles.radioGroup,
    ]
  )

  useEffect(() => {
    if (!budget?.acceptsWarranty) {
      budget.warrantyMonths = ''
    } else if (budget?.warrantyMonths === '') {
      budget.warrantyMonths = 0
    }

    if (!budget?.acceptsInstallment) {
      budget.installments = ''
    } else if (budget.installments === '') {
      budget.installments = 0
    }
  }, [
    budget?.acceptsInstallment,
    budget?.acceptsWarranty,
    budget?.warrantyMonths,
    budget?.installments,
  ])

  const budgetPDF = useMemo(
    () =>
      budget?.budgetPictures
        ?.filter(file =>
          file instanceof File
            ? file.type === 'application/pdf'
            : file.picture.indexOf('.pdf') !== -1
        )
        .map(item => {
          return item instanceof File ? item : { id: item.id, file: item.picture }
        }),
    [budget?.budgetPictures]
  )

  const budgetMedia = useMemo(
    () =>
      budget?.budgetPictures
        ?.filter(file =>
          file instanceof File
            ? file.type !== 'application/pdf'
            : file.picture.indexOf('.pdf') === -1
        )
        .map(item => {
          return item instanceof File ? item : { id: item.id, file: item.picture }
        }),
    [budget?.budgetPictures]
  )

  useEffect(() => {
    if (user.id) {
      setIsLoading(false)
    }
  }, [user])

  if (isLoading) {
    return <Loader open={isLoading} hasBackdrop label="Aguarde..." />
  }

  return (
    <Grid className={styles.container}>
      {PreviewComponent}
      <Grid className={styles.wrapper}>
        <Grid className={styles.labelWrapper}>
          <Typography variant="h5" component="p" className={styles.label}>
            Descrição
          </Typography>
          <Typography variant="h5" component="p" className={styles.label}>
            R$
          </Typography>
        </Grid>
        <Grid>
          <Grid className={styles.contentDescriptions}>{renderDescription}</Grid>
          {!isDisabled && isAbleToEdit && (
            <Button variant="outlined" onClick={onAddBudgetItem}>
              <AddIcon className={styles.icon} />
              Adicionar item
            </Button>
          )}
        </Grid>
        <Grid className={styles.totalWrapper} component="dl">
          <PriceSection
            budget={budget}
            handleInputChange={handleInputChange}
            disabled={isDisabled || !isAbleToEdit}
          />
        </Grid>
        <Grid className={styles.attachments}>
          <Attachments
            label="Fotos e vídeo do problema"
            readOnly={!isEdit || !isAbleToEdit}
            files={budgetMedia || []}
            downloadable
            canImportFiles
            accept={{
              'image/jpeg': [
                '.jpeg',
                '.png',
                '.jpg',
                '.bmp',
                '.webp',
                '.mkv',
                '.mp4',
                '.mov',
                '.avi',
                '.m4v',
                '.mpg',
                '.mpeg',
                '.wmv',
                '.webm',
              ],
            }}
            onDrop={onAddPicture}
            onRemoveItem={index => onRemovePicture(budgetMedia[index].id)}
            onImportFiles={() => onImportFiles()}
            onItemClick={index => {
              setPreviewState(() => ({
                visible: true,
                selectedIndex: index,
              }))
            }}
          />
          <Attachments
            label="Arquivos PDF"
            content="files"
            downloadable
            readOnly={!isEdit || !isAbleToEdit}
            files={budgetPDF || []}
            accept={{
              'application/pdf': ['.pdf'],
            }}
            onDrop={onAddPicture}
            onRemoveItem={index => onRemovePicture(budgetPDF[index].id)}
            onItemClick={index => window.open(budgetPDF[index].file)}
          />
        </Grid>
      </Grid>
      <Grid className={styles.options}>{paymentOptions.map(renderOptions)}</Grid>
      {isDeleteBudgetItemDialogOpen && (
        <DeleteBudgetItemDialog
          isOpen
          onClose={onToggleDeleteBudgetItemDialog}
          onDeleteBudgetItemClick={onConfirmDeleteBudgetItem}
        />
      )}
      {dialogMessage && <ConfirmRemoveDialog message={dialogMessage} />}
    </Grid>
  )
}

Details.propTypes = {
  isCancelled: PropTypes.bool,
  budget: PropTypes.shape({
    budgetItems: PropTypes.arrayOf(PropTypes.shape({})),
    acceptsInstallment: PropTypes.bool,
    installments: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    acceptsWarranty: PropTypes.bool,
    warrantyMonths: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    visitHappened: PropTypes.bool,
    totalPriceTradesman: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    referaCompletionPercentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    agencyCompletionPercentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    budgetPictures: PropTypes.arrayOf(PropTypes.shape({})),
    personPaying: PropTypes.string,
    personPayingEmail: PropTypes.string,
    personPayingWhatsapp: PropTypes.string,
    financialIncome: PropTypes.number,
  }).isRequired,
  isOriginalBudget: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  onRadioChange: PropTypes.func.isRequired,
  handleBudgetItemsChange: PropTypes.func.isRequired,
  onAddBudgetItem: PropTypes.func.isRequired,
  onRemoveBudgetItem: PropTypes.func.isRequired,
  isAbleToEdit: PropTypes.bool.isRequired,
  onAddPicture: PropTypes.func.isRequired,
  onRemovePicture: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Map),
}

Details.defaultProps = {
  isCancelled: false,
  errors: Map(),
}

export default React.memo(Details)
